import React, { useState } from "react";
import './nav.css'
import {Home} from 'react-feather'
import {Briefcase} from 'react-feather'
import {MessageSquare} from 'react-feather'

const FloatingNav = () => {

    const [activeNav, setActiveNav] = useState("#hero")

    return (
        <nav className="">
            <a href="#hero" className={activeNav === '#hero' ? 'active' : ''} onClick={() => setActiveNav("#hero")}><Home /></a>
            <a href="#work" className={activeNav === '#work' ? 'active' : ''} onClick={() => setActiveNav("#work")}><Briefcase /></a>
            <a href="#contact" className={activeNav === '#contact' ? 'active' : ''} onClick={() => setActiveNav("#contact")}><MessageSquare /></a>

        </nav>

    )
}

export default FloatingNav