import React from "react";
// import Shell from "../components/shell/Shell";
import { Footer, MantineProvider, Grid, Text } from "@mantine/core";
import { Link } from "gatsby";
import { Linkedin } from "react-feather";
import { Send } from "react-feather";
import { Gitlab } from "react-feather";
import { Tooltip } from "@mantine/core";
import FloatingNav from "../components/nav/nav";



const Layout = ({ children }) => {

    return (

        <MantineProvider
            theme={{ colorScheme: 'dark' }}
            withGlobalStyles
            // withNormalizeCSS
        >
            <div className="lg:px-32 px-4">
            {/* <container className="w-full"> */}
            

            {/* <Shell>
                {children}
            </Shell> */}
            {children}

            <Footer className="pb-36">
                {/* <Container className="center"> */}
                <Grid className="container flex justify-center mx-auto p-6 px-6">

                    <div className="w-full flex justify-center mx-auto pb-5 pt-3">
                        <div>

                            <Grid className="">
                                <Link to="https://www.linkedin.com/in/zacharybohl" target="_blank" className="px-2"><Tooltip label="Linkedin" position="right"><Linkedin className=" text-primary-blue" /></Tooltip></Link>
                                <Link to="mailto:zach.bohl@gmail.com" className="px-2"><Tooltip label="Email" position="right"><Send className=" text-primary-blue" /></Tooltip></Link>
                                <Link to="https://gitlab.com/zachbohl" target="_blank" className="px-2"><Tooltip label="Gitlab" position="right"><Gitlab className=" text-primary-blue" /></Tooltip></Link>
                                {/* <Link to="https://linkedin.com" target="_blank"><Twitter className=" text-primary-blue"/></Link> */}

                            </Grid>

                        </div>
                    </div>

                    <div className="flex justify-center mx-auto">
                        <Text>Designed & Built by Zach Bohl</Text>
                    </div>
                </Grid>
                {/* </Container> */}
            </Footer>


            {/* </container> */}
            </div>
        </MantineProvider>

    )
}

export default Layout